import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import axios from 'axios';
// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';
import toastr from "toastr";
import {
    Button,
   
    Input,
    Label,
    Row,
    FormGroup,
    FormFeedback,
    Alert
  } from "reactstrap";
  import { Formik, Form, Field, ErrorMessage } from 'formik';
  import * as Yup from 'yup';
  
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('İsim alanı zorunludur'),
    email: Yup.string().email('Geçerli bir e-posta adresi girin').required('E-posta zorunludur'),
    phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Geçerli bir telefon numarası girin')
    .min(10, 'Telefon numarası en az 10 karakter olmalıdır')
    .required('Telefon numarası zorunludur'),
  });

class GetInTouch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
            countries: [],
            selectedCountry: '',
            selectedDate: '',
            selectedTime: '',
            addExtraArray:[]
        };
    }
    noteSet = (event) => {
        console.log("msldfk",event.target.value)
        this.setState({ noteText: event.target.value });
    };

    handleDateChange = (event) => {
        this.setState({ selectedDate: event.target.value });
    };

    handleTimeChange = (event) => {
        this.setState({ selectedTime: event.target.value });
    };

   
 showToast= (type,desc,values) => { 
        let ele = null
        let message = "";
      
         if(type == true){
           ele = "success"
           message = desc
        } else{
           ele = "error"
           message = desc
        };
        const position = ["toast-top-right"];
        let toastType;
        const title = "Başlık";
      
      
        //Close Button
        const closeButton = true;
      
        //Debug
        const debug = false;
      
        //Progressbar
        const progressBar = true;
      
        //Duplicates
        const preventDuplicates = false;
      
        //Newest on Top
        const newestOnTop = true;
      
        //position class
        let positionClass = "toast-top-right";
      
        //Show Easing
        const showEasing = "swing";
      
        //Hide Easing
        const hideEasing = "linear";
      
        //show method
        const showMethod = "fadeIn";
      
        //Hide method
        const hideMethod = "fadeOut";
      
        //show duration
        const showDuration = 300;
      
        //Hide duration
        const hideDuration = 1000;
      
        //timeout
        const timeOut = 5000;
      
        //extended timeout
        const extendedTimeOut = 1000;
      
        //Fetch checked Type
       
         toastType = ele;
        
      
        toastr.options = {
          positionClass: positionClass,
          timeOut: timeOut,
          extendedTimeOut: extendedTimeOut,
          closeButton: closeButton,
          debug: debug,
          progressBar: progressBar,
          preventDuplicates: preventDuplicates,
          newestOnTop: newestOnTop,
          showEasing: showEasing,
          hideEasing: hideEasing,
          showMethod: showMethod,
          hideMethod: hideMethod,
          showDuration: showDuration,
          hideDuration: hideDuration
        };
      
        
          
        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
      }
      
      

    _openCampainDraw = async (val) => {
        try {
          //  e.preventDefault();

      
            console.log("nsdkf",this.state.nameText,this.state.mailAdress,this.state.phoneNumber,this.state.selectedDate,this.state.selectedTime,this.state.personCount,this.state.noteText,this.state.selectCook)
          let response = {
            Name_Surname : val.name,
            Mail_Adress:val.email,
            PhoneNumber:val.phoneNumber,
           // DateTime:this.state.selectedDate,
            Time:parseInt(this.state.timeSelect),
            Person:this.state.personCount,
            Web_Reservation_Cook:this.state.selectCook,
            Web_Reservation_Note:val.note,
           // Created_DateTime:null,
         // Web_Reservation_ClockTimeCount:parseInt(this.state.selectedTime)
            }
      
            axios.post('https://getjob.stechomeyazilim.info:5101/postWebReservation/send', response).then(async (res) => {

           
            console.log("nsdljkf",res)
            this.state.addExtraArray.map((item) => {

                let responseArray = {
                    Web_Reservation_Extra_Title : item.val,
                  // Web_Reservation_ID:res.data.value[0].ID,
                    }
              
                    axios.post('https://getjob.stechomeyazilim.info:5101/postWebReservationExtra/send', responseArray)
          
                })
            })


            this.showToast(true,'Başarıyla rezervasyon talebiniz oluşturuldu, en yakın sürede sizinle iletişime geçeceğiz')


           	
            
                  } catch (error) {
            console.error(error);
        } 
      }

    componentDidMount() {
        axios.get('https://restcountries.com/v3.1/all')
            .then(response => {
                // API'den gelen ülkeleri state'e kaydet
                this.setState({ countries: response.data });
            })
            .catch(error => {
                console.error('API hatası:', error);
            });
        const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactTekne/select/";

        fetch(apiUrl2)
            .then((response) => response.json())
            .then((data2) => {
                this.setState({ data2 });
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });

    }
    handleCountryChange = (event) => {
        this.setState({ selectedCountry: event.target.value });
    }

    _addExtra= async (value) => { 
        console.log("lksnf1112323",value)
     //   e.preventDefault();
 
     let checkData = this.state.addExtraArray.filter((x)=> x.val == value)

if (checkData.length == 0){
    this.setState(prevState => ({
        addExtraArray: [...prevState.addExtraArray,{
            val : value
        }]}))
}else{
    const filteredData = this.state.addExtraArray.filter(item => item.val !== value)
    this.setState({addExtraArray: filteredData})

}

    }
    _selectCook=  (value) => { 
        console.log("lksnf1112323",value)

            this.setState({
              selectCook:value
            })
       

    }


    _handleChange=  (value,type) => { 
        console.log("lksnf1112323",value,type)
        
        
        if(type=="time"){
            this.setState({
                timeSelect:value,
            })
          }

        if(type=="person"){
          this.setState({
            personCount:value,
          })
        }

        if(type=="name"){
            this.setState({
              nameText:value,
            })
          }

          if(type=="phone"){
            this.setState({
              phoneNumber:value,
            })
          }

          if(type=="mail"){
            this.setState({
              mailAdress:value,
            })
          }
    }

    render() {
        const { selectedDate, selectedTime } = this.state;
        const maxPersonCount = 500;
        const personCountOptions = [];

        for (let i = 1; i <= maxPersonCount; i++) {
            personCountOptions.push(
                <option key={i} value={i}>
                    {i} kişi
                </option>
            )
        }
        return (
            <>
                <Header1 />


                {/* <!-- Content --> */}
                <div className="page-content bg-white">



                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Rezervasyon</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Rezervasyon</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area bg-gray section-sp2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <div className="heading-bx">
                                            <h6 className="title-ext m-b0">Yat Kiralama</h6>
                                            <h3 className="title-head m-b0">Online Rezervasyon Talebi</h3>
                                            <div className="ttr-separator sepimg"></div>
                                            <p className="head-px2">Aşağıdaki formu kullanarak dakikalar içerisinde yat kiralama fiyatını öğrenebilir ve istediğiniz tarih için rezervasyon talebi gönderebilirsiniz</p>
                                        </div>
                                        <div className="row m-b30">
                                            <div className="col-md-12">
                                            <Formik
      initialValues={{ name: '', email: '' ,phoneNumber:'',note:''}}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        this._openCampainDraw(values)
        //console.log('Form Gönderildi', values);
        resetForm();
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
        <div className="ajax-form form-area-box get-in-touch" >
        <div className="ajax-message"></div>
        <div className="row placeani">
            <div className="col-lg-12">
                <div className="form-group">
                    <div className="">
                    <label htmlFor="name">İsim:</label>

                        <Input placeholder='İsim Giriniz'  onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              className="form-control"
               name="name" type="text" />
                    </div>

                    <ErrorMessage name="name" component="div" className="error-message" />

                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                    <div className="">
                    <label htmlFor="name">Mail:</label>

<Input placeholder='Mail Adresinizi Giriniz'  onChange={handleChange}
onBlur={handleBlur}
value={values.email}
className="form-control"
name="email" type="text" />
</div>

<ErrorMessage name="email" component="div" className="error-message" />

                </div>
            </div>
            <div className="col-lg-6">
            <div className="form-group">
                    <div className="">
                    <label htmlFor="name">Telefon Numarası:</label>

<Input placeholder='Telefon Numaranızı Giriniz'  onChange={handleChange}
onBlur={handleBlur}
value={values.phoneNumber}
className="form-control"
name="phoneNumber" type="text" />
</div>

<ErrorMessage name="phoneNumber" component="div" className="error-message" />

                </div>
            </div>
            <div className="col-lg-12">
                <div className="row m-b20">
                    <div className="col-md-12 col-lg-12">
                        <h5>Ekstralar</h5>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('pasta')} type="checkbox" className="custom-control-input" id="customControlAutosizing1" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing1">Pasta (650 Tl)</label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('lazer')} type="checkbox" className="custom-control-input" id="customControlAutosizing2" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing2">Lazer Gösterisi (1500 TL)</label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('volkan')} type="checkbox" className="custom-control-input" id="customControlAutosizing3" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing3">Volkan Gösterisi (400 TL)</label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('gül25adet')} type="checkbox" className="custom-control-input" id="customControlAutosizing4" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing4">Gül 25 Adet (1500 TL)</label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('fasıl')} type="checkbox" className="custom-control-input" id="customControlAutosizing5" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing5">Fasıl (5000 TL)</label>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('fotoğrafçı')} type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing6">Fotoğrafçı (8500 TL)</label>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('kemancı')} type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing6">Kemancı (2500 TL)</label>
                        </div>
                    </div>


                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('uçanbalon')} type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing6">Uçan Balon (2000 TL)</label>
                        </div>
                    </div>


                    <div className="col-md-12 col-lg-4">
                        <div className="custom-control custom-checkbox m-b10">
                            <input onChange={()=> this._addExtra('yatatransfer')} type="checkbox" className="custom-control-input" id="customControlAutosizing6" />
                            <label className="custom-control-label" htmlFor="customControlAutosizing6">Yata Transfer (3250 TL)</label>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-lg-6">
                <div className="form-group">
                    <label>Tarih:</label>
                    <div className="input-group">

                        <input
                            type="date"
                            className="form-control"
                            value={selectedDate}
                            onChange={this.handleDateChange}
                        />


                    </div>
                </div>
            </div>


            <div className="col-lg-6">
                <div className="form-group">
                    <label>Saat:</label>
                    <div className="input-group">


                        <input
                            type="time"
                            className="form-control"
                            value={selectedTime}
                            onChange={this.handleTimeChange}
                        />
                    </div>
                </div>
            </div>


            <div className="col-lg-6">
                <div className="form-group">
                    <div className="input-group">
                        <select value={this.state.selectedCountry} onChange={this.handleCountryChange}>
                            <option value="">Liman Seç</option>
                        
                            <option value="Arnavutköy">Arnavutköy</option>
                            <option value="Bebek">Bebek</option>
                            <option value="Kuruçeşme">Kuruçeşme</option>
                            <option value="Kuleli">Kuleli</option>
                            <option value="Beylerbeyi">Beylerbeyi</option>
             
                        </select>
                      
                    </div>     </div>     </div>
            <div className="col-lg-6">
                <div className="form-group">
                    <div className="input-group">
                        <select  onChange={e => this._handleChange(e.target.value,'time')} name="quote-request-reach">
                            <option value="">Kaç Saatlik Bir Tur Olacak</option>
                            <option value="2saat">2 saat</option>
                            <option value="3saat">3 saat</option>
                            <option value="4saat">4 saat</option>
                            <option value="5saat">5 saat</option>
                            <option value="6saat">6 saat</option>
                            <option value="7saat">7 saat</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="form-group">
                    <div className="input-group">
                        <select onChange={e => this._handleChange(e.target.value,'person')} name="quote-request-hear">
                            <option  value="">Kaç Kişi Olacaksınız</option>
                            {personCountOptions}
                        </select>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                    <div className="input-group">
                        <select onChange={e=> this._selectCook(e.target.value)}  name="quote-request-hear">
                            <option value="">Yemek Seçenekleri</option>
                            <option value="kahvalti">Kahvaltı (485 TL)</option>
                            <option value="sarkuteri">Şarküteri Tabağı (540 TL)</option>
                            <option value="yemek">Yemek (650 TL)</option>
                            <option value="no">İstemiyorum</option>


                        </select>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                    <div className="">
                        <label>Eklemek İstediğiniz Notlar</label>
                   

<Input placeholder='Eklemek İstediğiniz Notlar'
 type="textarea"
   onChange={handleChange}
onBlur={handleBlur}
value={values.note}
className="form-control"
name="note"  />
                    </div>

                    <ErrorMessage name="name" component="div" className="error-message" />


                </div>
            </div>
            <div className="col-lg-12">

            <button type="submit" className="btn button-md" onClick={handleSubmit}>
            Gönder
          </button>

            </div>
        </div>
    </div>
      )}
    </Formik>

                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <aside className="sticky-top left-border-1">
                                            <div className="widget">
                                                <h6 className="widget-title">Dİğer Sayfalar</h6>
                                                <ul className="service-list style-2">
                                                    <li><Link to="/hakkimizda">Hakkımızda <i className="las la-question-circle"></i></Link></li>
                                                    <li><Link to="/sss">SSS <i className="las la-question-circle"></i></Link></li>
                                                    <li className="active"><Link to="/iletisim">İletişim<i className="las la-quote-left"></i></Link></li>
                                                </ul>
                                                <Link to="/rezervasyon" className="btn btn-block m-t20">Rezervasyon</Link>
                                            </div>



                                            <div className="widget">
                                                <h6 className="widget-title">İletişim</h6>
                                                {this.state.data2.map((item) => (
                                                    <ul className="contact-infolist" key={item.ID}>
                                                        <li>
                                                            <img src={Icon1} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">İletişim Numarası</h6>
                                                            <p>{item.Phone} </p>
                                                        </li>
                                                        <li>
                                                            <img src={Icon2} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">E-mail Adres</h6>
                                                            <Link to="#">{item.Mail}</Link><br />

                                                        </li>
                                                        <li>
                                                            <img src={Icon3} alt="" className="mCS_img_loaded" />
                                                            <h6 className="contact-title">Adres</h6>
                                                            <p>{item.Adress}</p>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </aside>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default GetInTouch;