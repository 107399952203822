import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Image1 from "../../../images/about/pic11.jpg"
import Image2 from "../../../images/portfolio/portfolio-1/image_7.jpg"
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
class About1 extends Component{
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://tekneportapi.stechomeyazilim.com/Uploads/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getAboutTekne/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render(){
		return(
			<>
				{this.state.data2.map((item) => (
				<div className="row">
					<div className="col-md-6">
						<div className="about-img-box3">
							<div className="img1">
							<img  src={this.state.imageUrl+item.About_Image} alt=""/>
							</div>
							<div className="img2">
							<img  src={this.state.imageUrl+item.About_ImageSecond} alt=""/>
							</div>
						</div>
					</div>
				
					<div className="col-md-6">
						<div className="heading-bx m-b20">
							<h6 className="title-ext m-b0">Pruva Yatçılık</h6>
							<h2 className="title-head m-b0">{item.About_Title}</h2>
							<div className="ttr-separator sepimg"></div>
							<p className="head-px2">Pruva Yatçılık hakkında daha fazla bilgiye sahip olmak ister misiniz?</p>
						
						</div>
						<Link to="/hakkimizda" className="btn">Tıklayın <i className="ti-hand-point-up"></i></Link>
				
					</div>
						
				</div>
					))}
			</>
		);
	}
}

export default About1;
