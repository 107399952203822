import React, { Component } from 'react';
import Slider from "react-slick";

// Images
import Image1 from "../../../images/slider/slide1.jpg"
import Image2 from "../../../images/slider/slide2.jpg"

class Slider1 extends Component{
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://tekneportapi.stechomeyazilim.com/Uploads/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getSliderTekne/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}

	render(){
		
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-one slider-sp0">



				{this.state.data2.map((item) => (

					<div className="slider-item">
						<div className="slider-thumb">
						<img  src={this.state.imageUrl+item.Slider_Url} alt=""/>
						</div>
						<div className="slider-content text-white text-center">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">Pruva Yatçılık</h6>
									<h2 className="title">{item.SliderTitle}</h2>
									<a className="btn m-lr10" href="/rezervasyon">Rezervasyon <i className="ti-hand-point-up"></i></a>
				
								</div>
							</div>
						</div>
					</div>			
								))}
	



				</Slider>
				
			</>
		);
	}
}

export default Slider1;
