import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LogoWhite from "../../../images/logo-white.png"
import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"

class Footer1 extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://aktifguvenlikapi.stechomeyazilim.info/upload/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactTekne/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}

	render() {
		return (
			<>
				{/* <!-- Footer ==== --> */}
				<footer className="footer-style2">
					<div className="footer-top bt0">
						<div className="container">

							{this.state.data2.map((item) => (
								<div className="row align-items-center footer-info">
									<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
										<div className="feature-container left footer-info-bx">
											<div className="feature-lg text-white">
												<span className="icon-cell"><img src={IconImg3} alt="" /></span>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Adres</h5>
												<p>{item.Adress}</p>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
										<div className="feature-container left footer-info-bx">
											<div className="feature-lg text-white">
												<span className="icon-cell"><img src={IconImg1} alt="" /></span>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Telefon Numarası</h5>
												<p>{item.Phone}</p>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 m-sm-b30">
										<div className="feature-container left footer-info-bx">
											<div className="feature-lg text-white">
												<span className="icon-cell"><img src={IconImg2} alt="" /></span>
											</div>
											<div className="icon-content">
												<h5 className="ttr-tilte">Email Adresi</h5>
												<p>{item.Mail}</p>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 text-left text-lg-right">
										<Link to="/iletisim" className="btn btn-long d-sm-inline-block">İletişim</Link>
									</div>
								</div>
							))}

						</div>
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-3 col-md-5 col-sm-6">
									<div className="widget footer_widget">

										<ul>
											<li><Link to="index">Anasayfa</Link></li>
											<li><Link to="/hakkimizda">Hakkımızda</Link></li>
											<li><Link to="/sss">SSS</Link></li>
											<li><Link to="/iletisim">İletişim</Link></li>

										</ul>
									</div>
								</div>

								<div className="col-6 col-lg-5 col-md-12 col-sm-12">
									<div className="widget widget_info">
										<form className="subscribe-form m-b20" action="assets/script/mailchamp.php" method="post">
											<div className="ajax-message"></div>
								
											<p>Mail adresinizle abone olarak size özel tekliflerden yararlanabilirsiniz.</p>

											<div className="input-group">
												<input name="email" required="required" className="form-control" placeholder="Email Adres" type="email" />
												<div className="input-group-append">
													<button name="submit" value="Submit" type="submit" className="btn btn-block radius-sm">Abone Ol</button>
												</div>
											</div>
										</form>

										{this.state.data2.map((item) => (
										<ul className="list-inline ft-social-bx">
											<li><Link to={item.Facebook} className="btn button-sm" ><i className="fa fa-facebook"></i></Link></li>
											<li><Link to={item.Twitter} className="btn button-sm"><i className="fa fa-twitter"></i></Link></li>
											<li><Link to={item.Instagram} className="btn button-sm" ><i className="fa fa-instagram"></i></Link></li>
										</ul>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
									<p className="m-b0">Copyright © 2023
										<img src={LogoWhite} className="footer-b-logo m-lr5" width="50" alt="" />
										All Rights Reserved.</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-right">
									<ul className="link-inline">
										<li><Link to="/">Anasayfa</Link></li>
										<li><Link to="/hakkimizda">Hakkımızda</Link></li>
										<li><Link to="/sss">SSS</Link></li>
										<li><Link to="/iletisim">İletişim</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default Footer1;
