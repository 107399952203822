import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

class ServiceSlider1 extends Component{
	
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
				
		return(
			<>
			
				<Slider {...settings} className="slider-sp0 arrow-none slider-process">
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-world"></i> Biz Kimiz</h5>	
							<p>Bizimle iligili daha fazla bilgi edinebilirsiniz</p>				
							<Link to="/hakkimizda" className="process-btn">Daha Fazlası <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-crown"></i> Rezervasyon</h5>
							<p>Online rezervasyon işlemi gerçekleştirebilirsiniz</p>
							<Link to="/rezervasyon" className="process-btn">Daha Fazlası <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-pin2"></i> SSS</h5>
							<p>Merak ettiğiniz soruların cevabını bulabilirsiniz</p>
							<Link to="/sss" className="process-btn">Daha Fazlası <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-mobile"></i> Bize Ulaşın</h5>
							<p>Herhangi bir konu için bizimle iletişime geçebilirsiniz.</p>
							<Link to="/iletisim" className="process-btn">Daha Fazlası<i className="ti-arrow-right"></i></Link>
						</div>
					</div>
				</Slider>
			
			</>
		);
	}
}

export default ServiceSlider1;
