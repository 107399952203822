import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Images
import GridPic1 from "../../images/blog/grid/pic1.jpg"
import GridPic2 from "../../images/blog/grid/pic2.jpg"
import GridPic3 from "../../images/blog/grid/pic3.jpg"
import GridPic4 from "../../images/blog/grid/pic4.jpg"
import GridPic5 from "../../images/blog/grid/pic5.jpg"
import GridPic6 from "../../images/blog/grid/pic6.jpg"

class BlogClassic extends Component {
	constructor(props) {
		super(props);
		this.state = {
	
			data3: [],
			imageUrl: 'https://tekneportapi.stechomeyazilim.com/Uploads/',
		};
	}

	componentDidMount() {
		

			const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getExtraTekne/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


	}
	
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Organizasyonlar</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Organizasyonlar</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						


					

						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">


								{this.state.data3.map((item) => (
									<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.2s">
										<div className="recent-news">
											<div className="action-box" >
											<img style={{height:250}}    src={this.state.imageUrl+item.ExtraImage} alt=""/>
											</div>
											<div className="info-bx">
											
												<h4 className="post-title"><Link to={{ pathname: '/organizasyondetay', state: { _item: item } }} >{item.Title}</Link></h4>
												<p>{item.Description}</p>
												<div className="post-extra">
													<Link to={{ pathname: '/organizasyondetay', state: { _item: item } }}  className="btn-link black radius-xl">DETAYLAR</Link>
												</div>
											</div>
										</div>
									</div>
									))}
								</div>
							</div>
						</div>		
			
						
			
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default BlogClassic;