import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import Slider from '../elements/slider/slider1';
import ServiceSlider1 from '../elements/services/service-slider-1';
import About from '../elements/about/about2';
import Testimonial from '../elements/testimonial/testimonial1';



class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			imageUrl: 'https://tekneportapi.stechomeyazilim.com/Uploads/',
		};
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getSSSTekne/select";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactTekne/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getExtraTekne/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


	}

	render() {
		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<Slider />

					{/* <!-- Main Slider --> */}
					<div className="content-block" id="content-area">


						<div className="section-area process-area">
							<div className="container">
								<ServiceSlider1 />
							</div>
						</div>

						<div className="section-area section-sp3 bg-white">
							<div className="container">
								<About />
							</div>
						</div>



						<div className="section-area bg-white section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-4 col-md-12">
										<div className="heading-bx m-b20 text-center  text-lg-left m-md-b30">
											<h6 className="title-ext m-b0">SSS</h6>
											<h2 className="title-head m-b0">Sıkça Sorulan Sorular</h2>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Merak ettikleriniz ve daha fazlası için</p>
											<Link to="/sss" className="btn">Tıklayınız</Link>
										</div>
									</div>

									{this.state.data.slice(0, 2).map((item) => (
										<div className="col-lg-4 col-md-6 col-sm-6">



											<div className="service-box text-center m-xs-b30">


												<div className="service-media">

													<div className="service-inner">
														<Link to="/sss" className="btn white">Daha Fazlası</Link>
													</div>
												</div>
												<div className="service-info">
													<h4 className="title">{item.Title}</h4>
													<p>{item.Description}</p>
												</div>


											</div>

										</div>
									))}
								</div>
							</div>
						</div>

						<div className="section-area bg-primary section-sp2 client-appoint-box">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="heading-bx text-white">
											<h6 className="title-ext m-b0">İLETİşİM</h6>
											<div className="ttr-separator sepimg2"></div>
										</div>
										<div className="swiper-container client-area-box">
											<Testimonial />
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<form className="ajax-form" action="script/contact.php">
											<div className="apoint-btn-box">Bİze Yazın</div>
											<div className="ajax-message"></div>
											<div className="heading-bx text-white">
												<h6 className="title-ext m-b0"> Pruva Yatçılık</h6>
												<h2 className="title-head m-b0">Bizimle iletişime geçiniz</h2>
												<div className="ttr-separator sepimg2"></div>
											</div>
											<div className="row placeani appoint-bx1">
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="name" type="text" required className="form-control valid-character" placeholder="İsminiz" />
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="email" type="email" className="form-control" required placeholder="Email Adresiniz" />
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="phone" type="text" required className="form-control int-value" placeholder="Telefon Numaranız" />
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<div className="input-group input-bx">
															<textarea name="message" rows="4" className="form-control" placeholder="Mesajınız" required ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<button name="submit" type="submit" value="Submit" className="btn button-md"> Mesaj Gönder</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>

						<div className="section-area bg-white section-sp1 blog-area">
							<div className="container">
								<div className="row heading-bx align-items-end">
									<div className="col-lg-8 col-md-9">
										<h6 className="title-ext m-b0">TUR & ORGANİZASYONLAR</h6>
										<h2 className="title-head m-b0">
											Yat Kiralama Organizasyonları</h2>
										<div className="ttr-separator sepimg"></div>
									</div>
									<div className="col-lg-4 col-md-3">
										<Link to="/organizasyon" className="btn float-right">Daha Fazlası İçin</Link>
									</div>
								</div>
								<div className="row">


									{this.state.data3.slice(0, 1).map((item) => (
										<div className="col-md-6">
											<div className="recent-news">
												<div className="action-box">
													<img src={this.state.imageUrl + item.ExtraImage} alt="" />
												</div>
												<div className="info-bx">

													<h3 className="post-title"><Link to={{ pathname: '/organizasyondetay', state: { _item: item } }} >{item.Title}</Link></h3>
												</div>
											</div>
										</div>
									))}


									<div className="col-md-6">



										<div className="side-post">

											{this.state.data3.slice(1, 3).map((item) => (

												<div className="blog-post blog-md clearfix wow fadeInUp" data-wow-delay="0.2s">
													<div className="ttr-post-media">
														<Link to={{ pathname: '/organizasyondetay', state: { _item: item } }} >			<img src={this.state.imageUrl + item.ExtraImage} alt="" /></Link>
													</div>
													<div className="ttr-post-info">

														<h4 className="post-title"><Link to={{ pathname: '/organizasyondetay', state: { _item: item } }} >{item.Title}</Link></h4>
													</div>
												</div>
											))}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
				<div className="whatsapp-icon" style={{
					position: 'fixed',
					bottom: 20,
					left: 20,
					backgroundColor: '#25d366',
					color: 'white',
					width: 48,
					height: 48,
					borderRadius: 24,
					padding: 10,
					fontSize: 20,
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<a
						href="https://wa.me/1234567890"  // Replace with your WhatsApp number
						target="_blank"
						rel="noopener noreferrer"
					>
						<i style={{ color: 'white' }} className="fa fa-whatsapp"></i>
					</a>
				</div>


				<Footer1 />
			</>
		);
	}
}

export default Index;